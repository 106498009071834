import Vue from 'vue';
import App from './client/App.vue';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import firebase from './plugins/firebase';
import '@/client/phaser/css/index.css';
import '@/client/assets/fonts/fonts.css';

Vue.config.productionTip = false;
Vue.firebase = Vue.prototype.firebase = firebase;

new Vue({
	vuetify,
	render: h => h(App)
}).$mount('#app');
