<template>
	<div id="game-hud">
		<component :is="component" v-if="component" ref="component" />
	</div>
</template>


<script>
import LoadingScreen from '@/client/components/boot/LoadingScreen';

export default {
	name: 'HUD',
	data: function() {
		return {
			component: LoadingScreen
		};
	},
	mounted: function() {
		window.mainGameHUD = this;
	},
	components: {
		LoadingScreen
	}
};
</script>


<style scoped>
#game-hud {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	overflow: hidden;
	pointer-events: none;
}
</style>