<template>
    <v-app id="app" data-app>
        <game />

		<update-notification />
    </v-app>
</template>

<script>
import Game from '@/client/views/Game.vue';
import UpdateNotification from '@/client/components/utils/UpdateNotification';

export default {
	name: 'app',
	components: {
		Game,
		UpdateNotification
	}
};
</script>

<style>
#app {
    font-family: Orbitron, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100%;

    display: flex;
}

#app.v-application .title, #app.v-application .headline {
	font-family: Orbitron !important;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: hidden !important;
}
</style>
