<template>
  <div id="game-container" />
</template>


<script>
export default {
	name: 'PhaserCanvas'
};
</script>


<style scoped>
#game-container {
  height: 100%;
  overflow: hidden;
}
</style>