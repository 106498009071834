<template>
  <div class="game">
    <div id="game-wrapper">
      <phaser-canvas v-if="downloaded" />
      <HUD ref="hud" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PhaserCanvas from '@/client/components/phaser/PhaserCanvas';
import HUD from '@/client/components/phaser/HUD';

export default {
	name: 'game',
	data() {
		return {
			downloaded: false
		};
	},
	components: {
		PhaserCanvas,
		HUD
	},
	mounted() {
		if(this.$ga) {
			this.$ga.page('/');
		}

		import(/* webpackChunkName: "game" */ '@/client/phaser').then(gameInit => {
			this.downloaded = true;
			this.$nextTick(() => {
				var game = gameInit.launch();
				game.hud = this.$refs.hud;
				game.initFirebase(this.firebase);
				game.initSaveService();
				this.game = game;
			});
		});
	},
	beforeDestroy() {
		if(this.game) {
			this.game.destroy();
			delete this.game;
		}
	}
};
</script>

<style>
.game {
	flex: 1;
	min-height: 0;
	position: relative;
	background-color: black;
}

#game-wrapper {
	height: 100%;
}
</style>