import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
	apiKey: "AIzaSyBdS5VM2nSevx53zigeD0NA_QaLKFZhdoc",
	authDomain: "galaxy-total-war-phaser.firebaseapp.com",
	databaseURL: "https://galaxy-total-war-phaser.firebaseio.com",
	projectId: "galaxy-total-war-phaser",
	storageBucket: "galaxy-total-war-phaser.appspot.com",
	messagingSenderId: "696465601146",
	appId: "1:696465601146:web:9dcc35693a3b9ca032c1c3",
	measurementId: "G-09E2M8ZGS3"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export default firebase;