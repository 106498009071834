<template>
	<div class="loading-screen">
		<v-progress-circular v-model="loading" color="primary" size="100" width="12">{{ Math.round(loading) }}%</v-progress-circular>
		<p/>
		<div class="primary--text">Loading...</div>
	</div>
</template>


<script>
export default {
	name: 'LoadingScreen',
	data() {
		return {
			loading: 0
		};
	}
};
</script>

<style scoped>
	.loading-screen {
		position: absolute;
		top: 30%;
		left: 50%;
		transform: translateX(-50%);
	}
</style>